import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import { Chip } from '@material-ui/core';
import { LazyImage } from 'react-lazy-images';
import FancyBox from '../fancybox';
import VideoSource from '../VideoSource';
import Icon from '../Icon';
import './style.scss';
import { MediaStore } from '../../stores';

// FIXME If withEditor flag is true, also allow drag and drop for ordering - https://github.com/atlassian/react-beautiful-dnd
function MediaGrid(props) {
  const mediaMap = MediaStore.useState((s) => s.mediaMap);
  const [media, setMedia] = useState([]);
  const [thumbnailMedia, setThumbnailMedia] = useState([]);
  const [mediaToShow, setMediaToShow] = useState([]);
  const [countFrom, setCountFrom] = useState(5);

  useEffect(() => {
    // console.log('Media thumbnails:');
    // console.log(props.thumbnailMedia);
    setThumbnailMedia(props.thumbnailMedia);
  }, [props.thumbnailMedia]);

  useEffect(() => {
    setMedia(props.media);

    // console.log('Media grid items:');
    // console.log(props.media);

    setMediaToShow([...props.media]);

    if (countFrom && props.media.length > countFrom) {
      mediaToShow.length = countFrom;
    }
  }, [props.media]);

  useEffect(() => {
    if (props.countFrom <= 0 || props.countFrom > 5) {
      console.warn('countFrom is limited to 5!');
      setCountFrom(5);
    } else {
      setCountFrom(props.countFrom);
    }
  }, [props.countFrom]);

  const getImageSrc = (img) => img && img.src;
  const getVideoSrc = (video) => {
    if (video) {
      const localVideo = mediaMap[video.src];
      if (localVideo) {
        console.log('Has local video:');
        console.log(localVideo);
        // TODO Need to test that the video is still there - they may have deleted it for some reason...
        //  I really don't think we want a big map of Base64 versions of the video, that'd be way too much data cached
        //  so these need to be URIs... for now, the base64 is all we have to work with so I guess just ensure they don't
        //  persist. They will clear when the app is closed
        return localVideo;
      }
      return video.src;
    }
    return null;
  };

  const renderInnerContent = (source, placeholder) => {
    console.log('Source:');
    console.log(source);
    console.log('Placeholder:');
    console.log(placeholder);
    if (source) {
      if (source.type === 'video') {
        return (
          <VideoSource
            // fullScreen={props.media.length === 1}
            fullScreen
            videoSrc={getVideoSrc(source)}
            scaleWidth="100%"
            scaleHeight="100%"
            objectFit="cover"
            // placeholder={getImageSrc(placeholder)} // TODO Calculate this dynamically
            // title={item.title}
            onClick={() => {
              // TODO Show fullscreen rather than play in place
            }}
          />
        );
      }
      const imgSrc = getImageSrc(source);
      if (imgSrc && imgSrc.indexOf('base64') !== -1) {
        return <div style={{ backgroundImage: `url(${imgSrc})`, backgroundSize: 'cover', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }} className="main-img" />;
      }
      return (
        <LazyImage
          src={imgSrc}
          // srcSet={conditionalRender ? lazyImages[1 + offset].src : lazyImages[offset].src}
          alt="No photo description available"
          placeholder={({ imageProps, ref }) => <img ref={ref} src={getImageSrc(placeholder)} className="main-img img-blur" />}
          actual={({ imageProps }) => <img {...imageProps} itemProp="image" className="main-img" />}
        />
      );
    }
    return null;
  };

  const blockStyle = (source, heightPercentage) => {
    if (source) {
      if (source.type === 'video') {
        return { paddingTop: 0, overflow: 'hidden' };
      }
      return { paddingTop: `${heightPercentage}%`, overflow: 'hidden' };
    }
    return {};
  };

  const onImageClick = (media) => {
    if (media.type === 'image' && typeof props.onImageClick === 'function') {
      props.onImageClick(media);
    }
  };

  const renderOne = (offset = 0, heightPercentage = 50) => {
    const { lazyImages } = props;
    const overlay = media.length > countFrom && countFrom === 1 ? renderCountOverlay(true) : renderOverlay(offset);
    const imageClasses = 'border height-one background';
    let videoClasses = 'border video-one background';

    if (offset > 0) {
      videoClasses = 'border video-one-half background';
    }

    return (
      <Container>
        <Row>
          <Col
            xs={12}
            md={12}
            className={media.length && media[offset].type === 'video' ? videoClasses : imageClasses}
            style={blockStyle(thumbnailMedia[offset], heightPercentage)}
            onClick={() => onImageClick(media[offset])}
          >
            {renderInnerContent(thumbnailMedia[offset], lazyImages ? lazyImages[offset] : null)}
            {overlay}
          </Col>
        </Row>
      </Container>
    );
  };

  const renderTwo = (offset = 0) => {
    const { lazyImages, direction } = props;
    const imageClasses = 'border height-one background';
    let videoClasses = 'border video-two background';

    const overlay = media.length > countFrom && [2 + offset, 3 + offset].includes(+countFrom) ? renderCountOverlay(true) : renderOverlay(offset + 1);
    // console.log('Offset: ', offset);
    if (direction === 'horizontal' && offset > 1) {
      videoClasses = 'border video-two-half background';
    }

    return (
      <Container>
        <Row>
          <Col
            xs={6}
            className={media[offset].type === 'video' ? videoClasses : imageClasses}
            onClick={() => onImageClick(media[offset])}
          >
            {renderInnerContent(thumbnailMedia[offset], lazyImages ? lazyImages[offset] : null)}
            {renderOverlay(offset)}
          </Col>
          <Col
            xs={6}
            className={media[offset + 1].type === 'video' ? videoClasses : imageClasses}
            onClick={() => onImageClick(media[offset + 1])}
          >
            {renderInnerContent(thumbnailMedia[1 + offset], lazyImages ? lazyImages[1 + offset] : null)}
            {overlay}
          </Col>
        </Row>
      </Container>
    );
  };

  const renderThree = (offset = 0) => {
    const { lazyImages } = props;
    const overlay = !countFrom || countFrom > 5 || (media.length > countFrom && [4, 5].includes(+countFrom)) ? renderCountOverlay(true) : renderOverlay(offset + 2);
    const imageClasses = 'border height-three background';
    const videoClasses = 'border video-three background';

    // TODO Handle startIndex
    // FIXME xs may been to be 6 for horizontal

    return (
      <Container>
        <Row>
          <Col
            xs={4}
            md={4}
            className={media[offset].type === 'video' ? videoClasses : imageClasses}
            onClick={() => onImageClick(media[offset])}
          >
            {renderInnerContent(thumbnailMedia[offset], lazyImages ? lazyImages[offset] : null)}
            {renderOverlay(offset)}
          </Col>
          <Col
            xs={4}
            md={4}
            className={media[offset + 1].type === 'video' ? videoClasses : imageClasses}
            onClick={() => onImageClick(media[offset + 1])}
          >
            {renderInnerContent(thumbnailMedia[offset + 1], lazyImages ? lazyImages[offset + 1] : null)}
            {renderOverlay(offset + 1)}
          </Col>
          <Col
            xs={4}
            md={4}
            className={media[offset + 2].type === 'video' ? videoClasses : imageClasses}
            onClick={() => onImageClick(media[offset + 2])}
          >
            {renderInnerContent(thumbnailMedia[offset + 2], lazyImages ? lazyImages[offset + 2] : null,)}
            {overlay}
          </Col>
        </Row>
      </Container>
    );
  };

  const renderOverlay = (index, showContainer = true) => {
    const {
      hideOverlay,
      renderOverlay,
      overlayBackgroundColor,
      fancyBox,
      withEditor,
    } = props;
    console.log('Render Overlay Index: ', index);
    const file = media[index];
    console.log('File: ');
    console.log(file);
    if (file) {
      // TODO Use fancybox for video too? Just play the video fullscreen?
      // FIXME At least include the button to delete for video if withEditor
      if (file.type === 'video') {
        return (
          <div></div>
        );
      }
      if (fancyBox) {
        return (
          <div>
            <FancyBox
              tagName="a"
              className="rui-gallery-item"
              href={file.src}
              closeExisting
              popupClassName="rui-popup"
              galleryId="profile-gallery"
            >
              {showContainer ? (
                <div
                  style={{
                    position: 'absolute',
                    top: 0,
                    height: '100%',
                    width: '100%',
                  }}
                />
              ) : null}
            </FancyBox>
            {withEditor && (
              <span
                style={{
                  position: 'absolute',
                  top: 8,
                  left: 8,
                }}
                onClick={() => {
                  if (typeof props.onChange === 'function') {
                    console.log(`Editing ${file}`);
                    props.onEdit(index, file);
                  }
                }}
              >
                <Chip label={<span>Edit</span>} />
              </span>
            )}
            {withEditor && (
              <Chip
                className="circle-chip"
                style={{
                  position: 'absolute',
                  top: 8,
                  right: 8,
                }}
                onClick={() => {
                  if (typeof props.onChange === 'function') {
                    props.onChange(media.filter((e) => e !== file));
                  }
                  if (typeof props.onRemoved === 'function') {
                    props.onRemoved(file);
                  }
                }}
                label={<Icon name="x" />}
              />
            )}
          </div>
        );
      }
    }

    if (hideOverlay) {
      return false;
    }

    return [
      <div key={`cover-${index}`} className="cover slide" style={{ backgroundColor: overlayBackgroundColor }} />,
      <div key={`cover-text-${index}`} className="cover-text visible slide animate-text" style={{ fontSize: '100%' }}>
        {renderOverlay(index)}
      </div>,
    ];
  };

  const renderCountOverlay = (more) => {
    const extra = media.length - (countFrom && countFrom > 5 ? 5 : countFrom);
    return more && (
      <FancyBox
        tagName="a"
        className="rui-gallery-item"
        href={media[countFrom - 1].src}
        closeExisting
        popupClassName="rui-popup"
        galleryId="profile-gallery"
      >
        <div key="count-sub" className="cover-text visible" style={{ fontSize: '200%' }}><p>{`+${extra}`}</p></div>
      </FancyBox>
    );
  };

  const renderRemainingOverlays = () => media.slice(countFrom + 1).map((_, index) => renderOverlay(index + countFrom, false));
  const { direction } = props;

  if (direction === 'horizontal') {
    return (
      <div onClick={props.onClick}>
        <Row style={{ margin: 0 }}>
          <Col style={{ padding: 0, maxWidth: (media.length > 1 ? '50%' : 'inherit') }}>
            {renderOne(0, mediaToShow.length === 1 ? 80 : 100)}
          </Col>
          <Col style={{ padding: 0, maxWidth: (media.length > 1 ? '50%' : 'inherit') }}>
            {mediaToShow.length >= 2 && renderOne(1, mediaToShow.length === 2 ? 100 : 50)}
            {mediaToShow.length === 3 && renderOne(2, 50)}
            {mediaToShow.length >= 4 && renderTwo(2)}
            {media.length > 5 && renderRemainingOverlays()}
          </Col>
        </Row>
      </div>
    );
  }
  return (
    <div className="grid-container" onClick={props.onClick}>
      {[1, 3].includes(mediaToShow.length) && renderOne(0, mediaToShow.length === 1 ? 80 : 50)}
      {/* FIXME withEditor mode should only allow 5 max images */}
      {([2, 4].includes(mediaToShow.length) || mediaToShow.length >= 5) && renderTwo()}
      {mediaToShow.length === 3 && renderTwo(1)}
      {mediaToShow.length === 4 && renderTwo(2)}
      {mediaToShow.length > 4 && renderThree(2)}
      {media.length > 5 && renderRemainingOverlays()}
    </div>
  );
}

MediaGrid.propTypes = {
  media: PropTypes.array.isRequired,
  thumbnailMedia: PropTypes.array.isRequired,
  hideOverlay: PropTypes.bool,
  renderOverlay: PropTypes.func,
  overlayBackgroundColor: PropTypes.string,
  onClickEach: PropTypes.func,
  countFrom: PropTypes.number,
  direction: PropTypes.string,
  fancyBox: PropTypes.bool,
  onClick: PropTypes.func,
  withEditor: PropTypes.bool,
  onChange: PropTypes.func,
  onRemoved: PropTypes.func,
  onEdit: PropTypes.func,
};

MediaGrid.defaultProps = {
  hideOverlay: false,
  renderOverlay: () => '',
  overlayBackgroundColor: '#222222',
  onClickEach: null, // TODO Might want to add this back in case we want to allow comments on each individual photo
  countFrom: 5,
  direction: 'vertical',
  fancyBox: false,
  onClick: undefined,
  withEditor: false,
  onChange: null,
  onEdit: null,
};

export default MediaGrid;
