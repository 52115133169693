import { isMobile } from 'mobile-device-detect';
import List from '@material-ui/core/List';
import { Link, NavLink } from 'react-router-dom';
import ListItem from '@material-ui/core/ListItem';
import Avatar from '@material-ui/core/Avatar';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Badge from '@material-ui/core/Badge';
import ListSubheader from '@material-ui/core/ListSubheader';
import React, { memo } from 'react';
import classnames from 'classnames/dedupe';
import Hashids from 'hashids/cjs';
import { connect } from 'react-redux';
import { GroupStore, LoungeStore, UserStore } from '../../../stores';
import Placeholder from '../../../../config/placeholder.config';
import Icon from '../../Icon';
import { language, messages } from '../../../utils/localeUtils';
import { config } from '../index';
const hashids = new Hashids('', 12);

function Submenu({ auth, navigation }) {
  const loungeBadgeCount = LoungeStore.useState((s) => s.notificationCount);
  const groupBadgeCount = GroupStore.useState((s) => s.notificationCount);
  const friendBadgeCount = UserStore.useState((s) => s.friendRequestCount);

  const hasNotification = (data) => {
    if (data.path === `https://${config.loungeUrl}`) {
      return loungeBadgeCount > 0;
    }
    if (data.path === '/groups') {
      return groupBadgeCount > 0;
    }
    if (data.path === '/profile/friends') {
      return friendBadgeCount > 0;
    }
    return false;
  };

  const badgeContent = (data) => {
    if (data.path === `https://${config.loungeUrl}`) {
      return loungeBadgeCount;
    }
    if (data.path === '/groups') {
      return groupBadgeCount;
    }
    if (data.path === '/profile/friends') {
      return friendBadgeCount;
    }
    return 0;
  };

  const badgeColor = (data) => {
    if (data.name === 'Virtual Lounge' || data.name === 'Groups') {
      return 'primary';
    }
    return 'error';
  };

  const renderSubmenus = (nav, returnObject = false) => {
    let thereIsActive = false;

    console.log('Auth from drawer:');
    console.log(auth);

    if (isMobile) {
      return (
        <List>
          {isMobile
            ? (
              <div className="bottom-divider">
                <NavLink
                  to="/profile"
                  onClick={() => {
                  }}
                  style={{ color: 'rgba(0, 0, 0, 0.54)' }}
                >
                  {(auth.authenticated && auth.user)
                    ? (
                      <ListItem button key="show-profile">
                        <Avatar
                          src={auth.user.image_url && auth.user.image_url.replace('/users/profile/', '/48x48/users/profile/')}
                          style={{
                            height: 26,
                            width: 26,
                            marginRight: 22,
                            marginLeft: -5,
                          }}
                        />
                        <ListItemText primary={messages[language]?.profile || 'Profile'} />
                        <ListItemSecondaryAction style={{ right: 0 }}>
                          <div>
                            <Link
                              to={`/users/${hashids.encode(auth.user.id)}/nametag`}
                              style={{ padding: '14px' }}
                            >
                              <Icon
                                vendor="ion"
                                name="qr-code-outline"
                                className="rui-icon ion"
                                style={{
                                  width: 20,
                                  height: 20,
                                }}
                              />
                            </Link>
                            <Link to="/settings" style={{ padding: '14px' }}>
                              <Icon
                                name="settings"
                                style={{
                                  width: 20,
                                  height: 20,
                                }}
                              />
                            </Link>
                          </div>
                        </ListItemSecondaryAction>
                      </ListItem>
                    ) : (
                      <ListItem button key="show-profile">
                        <Avatar
                          src={Placeholder.user}
                          style={{
                            height: 26,
                            width: 26,
                            marginRight: 22,
                            marginLeft: -5,
                          }}
                        />
                        {/* TODO Get Started button here? */}
                        <ListItemText primary="Get Started" />
                      </ListItem>
                    )}
                </NavLink>
              </div>
            ) : null}
          {auth && auth.user && (auth.user.account_type === 'business' || auth.user.account_type === 'admin') && (
            <div>
              <a
                href={`${config.businessUrl}?token=${UserStore.getRawState().token}`}
                target="_blank"
                rel="noopener"
                className="menu-icons menu-list-heading"
              >
                <ListItem button key="business-manager-link">
                  <Icon name="business" vendor="material" style={{ marginRight: 30 }} />
                  <ListItemText primary="Boxpressd for Business" />
                  <ListItemSecondaryAction>
                    <Icon name="external-link" />
                  </ListItemSecondaryAction>
                </ListItem>
              </a>
            </div>
          )}
          {nav.map((data) => (
            <div>
              {data.path
                ? data.path.indexOf('http') !== -1 ? (
                  <a href={data.path} target="_blank" rel="noopener" className="menu-icons menu-list-heading">
                    <ListItem button key={data.name}>
                      <Icon name={data.icon} style={{ marginRight: 30 }} />
                      <ListItemText primary={data.name} />
                      <ListItemSecondaryAction>
                        <Icon name="external-link" />
                      </ListItemSecondaryAction>
                    </ListItem>
                  </a>
                ) : (
                  <NavLink
                    to={data.sub ? '#' : data.path}
                    onClick={data.action}
                    className={data.sub ? 'yay-sub-toggle menu-list-heading' : 'menu-list-heading'}
                  >
                    <ListItem button key={data.name}>
                      <Icon name={data.icon} style={{ marginRight: 30 }} />
                      <ListItemText primary={data.name} />
                      {hasNotification(data) ? (
                        <Badge
                          badgeContent={badgeContent(data)}
                          color={badgeColor(data)}
                          style={{ zIndex: 1000 }}
                        />
                      ) : null}
                    </ListItem>
                  </NavLink>
                ) : (
                  <ListSubheader component="div">
                    {data.name}
                  </ListSubheader>
                )}
            </div>
          ))}
        </List>
      );
    }

    const result = nav.map((data) => {
      const isActive = window.location.hash === `#${data.path}`;
      let isOpened = false;

      if (isActive) {
        thereIsActive = true;
      }

      let sub = '';
      if (data.sub) {
        const subData = renderSubmenus(data.sub, true);

        sub = (
          <ul className="yay-submenu dropdown-triangle">
            {subData.content}
          </ul>
        );

        if (subData.thereIsActive) {
          isOpened = true;
          thereIsActive = true;
        }
      }

      return (
        <React.Fragment key={`${data.path}-${data.name}`}>
          {data.label ? (
            <li className="yay-label">{data.label}</li>
          ) : ''}
          <li
            className={classnames({
              'yay-item-active': isActive,
              'yay-submenu-open': isOpened,
            })}
          >
            {data.path ? data.path.indexOf('http') !== -1 ? (
              <a href={data.path} target="_blank" rel="noopener">
                {data.icon ? (
                  <>
                    <span className="yay-icon">
                      <Icon name={data.icon} />
                    </span>
                    <span>{data.name}</span>
                    <span className="rui-yaybar-circle" />
                  </>
                ) : (
                  data.name
                )}
              </a>
            ) : (
              <NavLink
                to={data.sub ? '#' : data.path}
                onClick={data.action}
                className={data.sub ? 'yay-sub-toggle' : ''}
              >
                {data.icon ? (
                  <>
                    {hasNotification(data) ? (
                      <Badge
                        badgeContent={badgeContent(data)}
                        color="error"
                        style={{
                          zIndex: 1000,
                          marginLeft: -6,
                          position: 'absolute',
                          marginTop: -14,
                        }}
                      />
                    ) : null}
                    <span className="yay-icon">
                      <Icon name={data.icon} />
                    </span>
                    <span>{data.name}</span>
                    <span className="rui-yaybar-circle" />
                  </>
                ) : (
                  data.name
                )}
                {data.sub ? (
                  <span className="yay-icon-collapse">
                    <Icon name="chevron-right" strokeWidth="1" className="rui-icon-collapse" />
                  </span>
                ) : ''}
              </NavLink>
            ) : (
              <span
                style={{
                  display: 'block',
                  marginLeft: 20,
                  marginTop: 8,
                  marginBottom: 8,
                  fontWeight: 700,
                }}
              >
                {data.name}
              </span>
            )}
            {sub}
          </li>
        </React.Fragment>
      );
    });

    if (returnObject) {
      return {
        content: result,
        thereIsActive,
      };
    }

    return result;
  };
  return (
    <>
      {renderSubmenus(navigation)}
    </>
  );
}

function mapStateToProps(state) {
  return {
    auth: state.get('auth').toJS(),
  };
}

export default memo(connect(mapStateToProps)(Submenu));
